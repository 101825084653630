export default (initialData) => ({
    zoom: initialData.zoom,
    lat: initialData.lat,
    lng: initialData.lng,
    markers: initialData.markers,

    init() { 
        window.addEventListener('gm:api-loaded', () => this.apiLoadedHandler());
        if (!('initMapCallback' in window)){
            window['initMapCallback'] = function() {
                window.dispatchEvent(new CustomEvent('gm:api-loaded'));
            }
            var apiKey = document.querySelector('meta[name="gmkey"]').content;
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = "https://maps.googleapis.com/maps/api/js?key=" + apiKey + "&callback=initMapCallback";
            document.body.appendChild(script);
        }
    },

    apiLoadedHandler() {
        google.maps.event.addDomListener(window, 'load', this.createMap());
    },

    createMap() {
		var mapOptions = {
			scrollwheel: false,
			scaleControl: false,
			mapTypeControl: false,
			streetViewControl: false,
			navigationControl: false,
			fullscreenControl: false,

			zoom: this.zoom,
			center: new google.maps.LatLng(this.lat,this.lng),

			styles: [ { "featureType": "water", "elementType": "geometry", "stylers": [ { "color": "#e9e9e9" }, { "lightness": 17 } ] }, { "featureType": "landscape", "elementType": "geometry", "stylers": [ { "color": "#f5f5f5" }, { "lightness": 20 } ] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [ { "color": "#ffffff" }, { "lightness": 17 } ] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [ { "color": "#ffffff" }, { "lightness": 29 }, { "weight": 0.2 } ] }, { "featureType": "road.arterial", "elementType": "geometry", "stylers": [ { "color": "#ffffff" }, { "lightness": 18 } ] }, { "featureType": "road.local", "elementType": "geometry", "stylers": [ { "color": "#ffffff" }, { "lightness": 16 } ] }, { "featureType": "poi", "elementType": "geometry", "stylers": [ { "color": "#f5f5f5" }, { "lightness": 21 } ] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [ { "color": "#dedede" }, { "lightness": 21 } ] }, { "elementType": "labels.text.stroke", "stylers": [ { "visibility": "on" }, { "color": "#ffffff" }, { "lightness": 16 } ] }, { "elementType": "labels.text.fill", "stylers": [ { "saturation": 36 }, { "color": "#333333" }, { "lightness": 40 } ] }, { "elementType": "labels.icon", "stylers": [ { "visibility": "off" } ] }, { "featureType": "transit", "elementType": "geometry", "stylers": [ { "color": "#f2f2f2" }, { "lightness": 19 } ] }, { "featureType": "administrative", "elementType": "geometry.fill", "stylers": [ { "color": "#fefefe" }, { "lightness": 20 } ] }, { "featureType": "administrative", "elementType": "geometry.stroke", "stylers": [ { "color": "#fefefe" }, { "lightness": 17 }, { "weight": 1.2 } ] } ] 
        };

		var map = new google.maps.Map(this.$refs.map, mapOptions);

        this.markers.forEach(marker => {
            new google.maps.Marker({
                map: map,
                position: new google.maps.LatLng(marker.lat,marker.lng),
                title: marker.title
            });
        });
	}
})