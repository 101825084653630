require('../css/main.css');

import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import intersect from '@alpinejs/intersect';
import persist from '@alpinejs/persist';
import Form from './FormHandler.js';
import Map from './Map.js';


function getParameterByName(name) { 
    if(name !== "" && name !== null && name != undefined){
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),        results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }else{
        return "";        }   
}

// Give the URL parameters variable names
if(getParameterByName('utm_source') !== ""){
    localStorage.setItem('source', getParameterByName('utm_source'));
}
if(getParameterByName('utm_medium') !== ""){
    localStorage.setItem('medium', getParameterByName('utm_medium'));
}
if(getParameterByName('utm_campaign') !== ""){
    localStorage.setItem('campaign', getParameterByName('utm_campaign'));
}

Alpine.data('form', Form);
Alpine.data('map', Map);
Alpine.plugin(focus);
Alpine.plugin(intersect);
Alpine.plugin(persist);
window.Alpine = Alpine;
Alpine.start();


if (!!document.querySelector(".js-swiper")){
    import( './Swiper.js').then(module => {module.default('.js-swiper')});
}

if (!!document.querySelector(".js-gallery")){
    import( './Gallery.js').then(module => {module.default('.js-gallery')});
}

if (!!document.querySelector(".shadow-root")){
    //jQuery Laden
    var jq = document.createElement('script');
    jq.src = "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js";
    document.getElementsByTagName('head')[0].appendChild(jq);
    jq.onload = function() {
        jQuery.noConflict();
        //default functions
        import( './Cookiebar.js');
    };
}




